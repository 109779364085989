"use client";

/**
 * Third-party libraries.
 */
import { NotificationInstance } from "antd/es/notification/interface";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useState,
} from "react";

/**
 * Project components
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import {
  UserAvailabilityStatus,
  useSystemPreferenceEventSubscription,
  useUserEventSubscription,
  useUserUpdateAvailabilityMutation,
} from "@/components/client/graphql";
import { useNotificationContext } from "@/components/client/notification";

/**
 * Arguments of the set user availability status function.
 */
type SetUserAvailabilityStatusArgs = {
  /**
   * Indicates that only the local state would be updated.
   * Database entry will not be affected.
   */
  local?: boolean;
  /**
   * The user availability status.
   */
  status: UserAvailabilityStatus;
};

/**
 * Application context.
 */

export type ApplicationContext = {
  /**
   * Indicates if the application is in mock data mode.
   */
  mockData: boolean;
  /**
   * Ant Design notification instance.
   *
   * Use this to show notifications.
   */
  notification: NotificationInstance;
  /**
   * Sets the application to use mock data.
   */
  setMockData: Dispatch<SetStateAction<boolean>>;
  /**
   * Shows or hides the dialer.
   */
  setShowDialer: Dispatch<SetStateAction<boolean>>;
  /**
   * Shows or hides the settings modal.
   */
  setShowSettings: Dispatch<SetStateAction<boolean>>;
  /**
   * User availability status setter.
   */
  setUserAvailabilityStatus: (args: SetUserAvailabilityStatusArgs) => void;
  /**
   * Indicates if the dialer is shown or hidden.
   *
   * @default false
   */
  showDialer: boolean;
  /**
   * Indicates if the settings modal is shown or hidden.
   */
  showSettings: boolean;
  /**
   * Updating user availability status.
   */
  updatingUserAvailabilityStatus: boolean;
  /**
   * The availability status of the user.
   *
   * @default offline.
   */
  userAvailabilityStatus: UserAvailabilityStatus;
};

/**
 * Application context.
 */
const ApplicationContext = React.createContext<ApplicationContext>({
  mockData: false,
  notification: {
    success: () => {},
    error: () => {},
    info: () => {},
    warning: () => {},
    open: () => {},
    destroy: () => {},
  },
  setMockData: () => {},
  setShowDialer: () => {},
  setShowSettings: () => {},
  setUserAvailabilityStatus: () => {},
  showDialer: false,
  showSettings: false,
  updatingUserAvailabilityStatus: false,
  userAvailabilityStatus: UserAvailabilityStatus.Offline,
});

/**
 * Use Application Context hook.
 */
export const useApplicationContext = () => {
  return React.useContext(ApplicationContext);
};

/**
 * Application context provider.
 */
export const ApplicationContextProvider = ({ children }: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  const { notification } = useNotificationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================
  /**
   * Indicates wheter the application should use mock data.
   */
  const [mockData, setMockData] =
    useState<ApplicationContext["mockData"]>(false);

  /**
   * Shows or hides the dialer.
   */
  const [showDialer, setShowDialer] =
    useState<ApplicationContext["showDialer"]>(false);

  /**
   * Shows or hides the settings modal.
   */
  const [showSettings, setShowSettings] =
    useState<ApplicationContext["showSettings"]>(false);

  /**
   * The user availability status.
   */
  const [userAvailabilityStatus, _setUserAvailabilityStatus] = useState<
    ApplicationContext["userAvailabilityStatus"]
  >(UserAvailabilityStatus.Offline);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [
    updateUserAvailabilityStatus,
    { loading: updatingUserAvailabilityStatus },
  ] = useUserUpdateAvailabilityMutation();

  useUserEventSubscription();

  useSystemPreferenceEventSubscription();

  // ===========================================================================
  // ===========================================================================
  // Function
  // ===========================================================================
  // ===========================================================================

  /**
   * Updates the user availability status on the server first before updating the
   * state. This is to ensure that the status is synced to the server first
   * before reflecting changes.
   */
  const setUserAvailabilityStatus = useCallback(
    ({ local, status }: SetUserAvailabilityStatusArgs) => {
      const errorMessage = `Error updating user availability status to ${status}. Please try again.`;

      /**
       * Do not allow the user to update the status if the user is not logged in.
       */
      if (!user) {
        console.warn("User is not logged in.");
        return;
      }

      if (local) {
        _setUserAvailabilityStatus(status);
        return;
      }

      /**
       * Do not allow the user to update the status if the status is currently
       * being updated. This is to prevent multiple updates at the same time.
       */
      if (updatingUserAvailabilityStatus) {
        console.warn("User availability status is currently being updated.");
        return;
      }

      updateUserAvailabilityStatus({
        variables: {
          input: {
            availabilityStatus: status,
          },
        },
        onCompleted: async function (data, clientOptions) {
          if (status !== data.userUpdateAvailability.availability.status) {
            console.error(errorMessage);

            notification.error({
              message: "Error updating user availability status.",
              description: errorMessage,
              showProgress: true,
              pauseOnHover: true,
            });

            return;
          }

          _setUserAvailabilityStatus(
            data.userUpdateAvailability.availability.status,
          );
        },
        onError(error, clientOptions) {
          const errorMessage = `Error updating user availability status to ${status}. Please try again.`;

          console.error(errorMessage, error);

          notification.error({
            message: "Error updating user availability status.",
            description: errorMessage,
            showProgress: true,
            pauseOnHover: true,
          });
        },
      });
    },
    [
      notification,
      updateUserAvailabilityStatus,
      updatingUserAvailabilityStatus,
      user,
    ],
  );

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <ApplicationContext.Provider
      value={{
        mockData,
        notification,
        setMockData,
        setShowDialer,
        setShowSettings,
        setUserAvailabilityStatus,
        showDialer,
        showSettings,
        updatingUserAvailabilityStatus,
        userAvailabilityStatus,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
