/**
 * Keys used for defining a system preference.
 * Values accepted by the keys are defined on the documentation of each key.
 */
export enum SystemPreferenceKey {
  /**
   * Indicates whether the business is open or closed.
   *
   * Valid values are:
   *  - open: The system will accept client calls.
   *  - closed: The system will reject client calls.
   */
  BUSINESS_STATUS = "business-status",
  /**
   * Indicates whether the system should use the Session Initiation Protocol (SIP).
   * Turning this on would use the SIP Phone Number as the default phone number for
   * outbound calls.
   *
   * Valid values are:
   * - enabled
   * - disabled
   */
  SESSION_INITIATION_PROTOCOL = "session-initiation-protocol",
  /**
   * The preferred timezone for the system.
   *
   * Valid values are:
   * - Asia/Singapore
   * - America/New_York
   * - (etc.)
   *
   * For a list of valid timezones print: `Intl.supportedValuesOf("timeZone")`
   * in any javascript console.
   */
  SYSTEM_TIME_ZONE = "system-time-zone",
  /**
   * Indicates whether call recordings are enabled or disabled.
   *
   * Valid values are:
   * - enabled
   * - disabled
   */
  CALL_RECORDINGS = "call-recordings",
}
