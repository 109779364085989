"use client";

/**
 * Third-party library.
 */
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

/**
 * Project components.
 */
import { Auth0User } from "@/components/common/auth0/types";
import { ApiRoute } from "@/components/common/route";

/** The AuthContext value. */
type AuthenticationContextValue = {
  /** The authenticated user object. */
  user: Auth0User | null;
  /**
   * A function to check if still authenticated. This is best-practice to check
   * if still authenticated. Under the hood, it checks the `expires_at` value
   * of the token.
   */
  isAuthenticated: () => boolean;
  /** True when loading both 'initially' or when 'refetching'. Inspired by react-query. */
  fetching: boolean;
  /**
   * True when loading ONLY 'initially'. Never becomes `true` again unless
   * this component remounts again (full-page reload). Inspired by react-query.
   *
   * ```ts
   * // Can be used for checking if refetching and show a special UI for it.
   * const isRefetching = fetching && !loading;
   * ```
   */
  loading: boolean;
  /** The error value if the authenticated user could not be fetched. */
  error?: Error;
  /** Call this to refetch the user. */
  refetch: () => void;
};

// I. Context
const AuthenticationContext = createContext<AuthenticationContextValue>({
  user: null,
  isAuthenticated: () => false,
  fetching: false,
  loading: false,
  error: undefined,
  refetch: () => {},
});

// II. Hook
export const useAuthenticationContext = () => useContext(AuthenticationContext);

// III. Provider Component
export const AuthenticationContextProvider: FC<PropsWithChildren> = (props) => {
  const [user, setUser] = useState<Auth0User | null>(null);
  const [error, setError] = useState<Error>();
  // we are fetching always initially.
  const [fetching, setFetching] = useState(true);

  // For `loading` - so that it never becomes true after the initial fetch.
  const [loading, setLoading] = useState(true);
  const [finishedInitialFetch, setFinishedInitialFetch] = useState(false);

  /**
   * This implementation is based on
   * https://community.auth0.com/t/check-if-user-is-authenticated-or-not-auth-js-sdk/38452/3
   */
  const isAuthenticated = useCallback(() => {
    if (!user) return false;

    /** now timestamp in ms. */
    const now = new Date().getTime();

    /** expires_at timestamp in ms. */
    const expiresAt = user.expires_at * 1000;

    return now < expiresAt;
  }, [user]);

  const fetchUser = useCallback(async () => {
    setFetching(true);

    if (!finishedInitialFetch) setLoading(true);

    try {
      const response = await fetch(ApiRoute.AUTHENTICATION_ME);

      if (!response.ok) {
        // throw error?
      }

      const result = (await response.json()) as { user: Auth0User | null };

      if (result.user) {
        /** Number of milliseconds before token expires. */
        const msBeforeExpire = result.user?.expires_at - new Date().getTime();

        // Side effect: Try to call fetchUser again when token expires.
        // So user doesn't have to refresh the app to get logged out.
        if (msBeforeExpire > 0) setTimeout(fetchUser, msBeforeExpire);
      }

      setUser(result.user ?? null);
    } catch (e: unknown) {
      // Type assertion to `unknown` is necessary here.
      // Otherwise, error ts(1196): must be Catch clause variable type annotation
      // must be 'any' or 'unknown' if specified.ts(1196)
      const error = e as Error;

      setError(error);
    } finally {
      setFetching(false);

      setFinishedInitialFetch(true);

      setLoading(false);
    }
  }, [finishedInitialFetch]);

  // Fetch the user on load.
  useEffect(() => {
    // We only want to fetchUser the first time.
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      user: user,
      isAuthenticated: isAuthenticated,
      fetching: fetching,
      loading: loading,
      error: error,
      refetch: () => {
        fetchUser();
      },
    }),
    [error, fetchUser, fetching, isAuthenticated, loading, user]
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};
